import type { SetRecorder } from "../Interfaces/RecorderControlProps";
import type { Recorder } from "../Interfaces/Recorder";

export const startRecording = async (setRecorderState: SetRecorder) => {
  try {
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });

    setRecorderState((prevState: Recorder) => ({
      ...prevState,
      initRecording: true,
      mediaStream: audioStream,
    }));
  } catch (err: unknown) {
    console.error(err);
  }
};

export const saveRecording = (recorder: MediaRecorder) => {
  if (recorder.state !== "inactive") recorder.stop();
};