import React from "react";
import type { WordsInterface } from "../Interfaces";
import "../../../main.css";

interface WordsProps {
  words: WordsInterface[];
  onWordSelected: (WordsInterface) => void;
}

export const Words = ({ words, onWordSelected }: WordsProps) => {
  const selectWord = (word: WordsInterface) => {
    onWordSelected(word);
  };

  const freeWord = () => {
    onWordSelected(undefined);
  };

  return words.length !== 0 ? (
    <div className="porehu-words-wrapper noselect" data-testid="wordlist">
      <div className="porehu-title">Words</div>
      {words.map((word, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          role="button"
          tabIndex={0}
          onMouseDown={() => selectWord(word)}
          onMouseUp={() => {
            freeWord();
          }}
          className={`porehu-word ${word.isSelected ? "selected" : ""}`}
        >
          {word.word}
        </div>
      ))}
    </div>
  ) : null;
};
