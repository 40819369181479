/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint no-else-return :"error" */
import { useState, useEffect } from "react";
import { startRecording, saveRecording } from "./AudioControl";
import type { Recorder } from "../Interfaces/Recorder";
import type {
  Interval,
  AudioTrack,
  MediaRecorderEvent,
} from "../Interfaces/RecorderControlProps";

const initialState: Recorder = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,

};

const ONE_SECOND_UNDER_MINUTE = 59;
const TIME_OUT = 1000;

export const useRecorder = () => {
  const [recorderState, setRecorderState] = useState(initialState);

  useEffect(() => {
    const MAX_TIME = 5;
    let recordingInterval: Interval = null;

    if (recorderState.initRecording)
      recordingInterval = setInterval(() => {
        setRecorderState((prevState: Recorder) => {
          if (
            prevState.recordingMinutes === MAX_TIME &&
            prevState.recordingSeconds === 0
          ) {
            typeof recordingInterval == "number" &&
              clearInterval(recordingInterval);

            return prevState;
          } else if (
            prevState.recordingSeconds >= 0 &&
            prevState.recordingSeconds < ONE_SECOND_UNDER_MINUTE
          )
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };
          else if (prevState.recordingSeconds === ONE_SECOND_UNDER_MINUTE)
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            };

          return prevState;
        });
      }, TIME_OUT);
    else
      typeof recordingInterval === "number" && clearInterval(recordingInterval);

    return () => {
      typeof recordingInterval === "number" && clearInterval(recordingInterval);
    };
  });

  useEffect(() => {
    setRecorderState((prevState) => {
      if (prevState) {
        if (prevState.mediaStream)
          return {
            ...prevState,
            mediaRecorder: new MediaRecorder(prevState.mediaStream),
          };
      }

      return prevState;
    });
  }, [recorderState.mediaStream]);

  useEffect(() => {
    const recorder = recorderState.mediaRecorder;
    let chunks: Blob[] = [];

    if (recorder && recorder.state === "inactive") {
      recorder.start();
      recorder.ondataavailable = (e: MediaRecorderEvent) => {
        chunks.push(e.data);
      };
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
        chunks = [];

        setRecorderState((prevState: Recorder) => {
          if (prevState.mediaRecorder)
            return {
              ...initialState,
              audio: window.URL.createObjectURL(blob),
            };

          return initialState;
        });
      };
    }

    return () => {
      if (recorder)
        recorder.stream
          .getAudioTracks()
          .forEach((track: AudioTrack) => track.stop());
    };
  }, [recorderState.mediaRecorder]);

  return {
    recorderState,
    startRecording: async () => startRecording(setRecorderState),
    cancelRecording: () => setRecorderState(initialState),
    saveRecording: () => {
      if (recorderState.mediaRecorder) {
        
        return saveRecording(recorderState.mediaRecorder);
      }
    },
  };
};
