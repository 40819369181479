export const prevRecords = [
  {
    id: "123",
    dateTime: "1658024640000",
    audio: "https://folksong.org.nz/atu_i_te_uenuku/uenuku.mp3",
  },
  {
    id: "124",
    dateTime: "1658107440000",
    audio: "https://folksong.org.nz/atu_i_te_uenuku/uenuku.mp3",
  },
];
