import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { v4 as uuid4 } from "uuid";
import "../../../../../main.css";
import type { DragAnswerOptionsInterface } from "../LessonProps";
import { AnswerBubble, AnswerBox } from "./AnswerBubble";
import { AudioPlayButton } from "../../AudioRecording/Components";

export const DraggableQuestion = ({
  currentQuestion,
  answerOptions,
  handleSubmit,
  incorrectCounter,
  correctCounter,
}: DragAnswerOptionsInterface) => {
  const {
    themeColour,
    whakautu,
    pātaiMātātuhi,
    pātaiWhakarongo,
    pātaiKupu,
    pātaiLabel = "Pātai",
  } = currentQuestion;
  const TIME_OUT = 550;
  const splitWhakautu = whakautu.split("_1_");
  const [location, setLocation] = useState<{
    x: { min: number; max: number };
    y: { min: number; max: number };
  }>();

  const validateCoord = ({ x, y }) => {
    if (location) {
      return (
        x > location.x.min &&
        x < location.x.max &&
        y > location.y.min &&
        y < location.y.max
      );
    }

    return false;
  };

  const [variant, setVariant] = useState<boolean | null>(null);
  useEffect(() => {
    if (correctCounter) {
      setVariant(true);
      const timer = setTimeout(() => setVariant(null), TIME_OUT);

      return () => clearTimeout(timer);
    }
  }, [correctCounter]);
  useEffect(() => {
    if (incorrectCounter) {
      setVariant(false);
      const timer = setTimeout(() => setVariant(null), TIME_OUT);

      return () => clearTimeout(timer);
    }
  }, [incorrectCounter]);

  const answerBoxLoc = (e) => {
    setLocation(e);
  };
  const handleDrop = (e, str) => {
    if (validateCoord(e)) {
      handleSubmit(str);
    }
  };
  const [allRendered, setAllRendered] = useState(false);
  useEffect(() => {
    setAllRendered(true);
  }, []);

  return (
    <Container
      fluid="x-lg"
      className="whakamaori-question-container"
      style={{ borderColor: themeColour }}
      data-testid="question-container"
    >
      <Container className="whakamaori-lesson-inner">
        <Row className="whakamaori-row">
          <h2>{pātaiLabel}</h2>
        </Row>
        {pātaiKupu}
        {pātaiMātātuhi && (
          <img
            className="whakamaori-image-question"
            src={pātaiMātātuhi}
            alt="pātai-mātātuhi"
          />
        )}
        {pātaiWhakarongo && (
          <Container className="whakamaori-whakarongo-question">
            <AudioPlayButton
              className="whakamaori-primary-audio-controls-large"
              audioURL={pātaiWhakarongo}
              playIcon="volume"
              pauseIcon="volumeSlash"
            />
          </Container>
        )}
      </Container>
      <Container
        fluid="x-lg"
        className="whakamaori-question-container"
        style={{ backgroundColor: themeColour }}
        data-testid="draggable-question-container"
      >
        <Container
          fluid="x-lg"
          className="whakamaori-question-container"
          data-testid="draggable-question-inner"
          style={{ backgroundColor: themeColour }}
        >
          <Row bsPrefix="whakamaori-row">
            <h2 className="whakamaori-subheader-mā"> {splitWhakautu[0]}</h2>
            {allRendered && (
              <AnswerBox
                location={(e) => {
                  answerBoxLoc(e);
                }}
                animation={variant}
              />
            )}
            <h2 className="whakamaori-subheader-mā"> {splitWhakautu[1]}</h2>
          </Row>
          <Container
            fluid="x-lg"
            className="whakamaori"
            data-testid="draggable-question-inner"
          >
            <Row className="whakamaori-row-bubble">
              {answerOptions.map((answer, i) => (
                <AnswerBubble
                  key={uuid4()}
                  answer={answer}
                  isSelected
                  onSelect={(e, str) => {
                    handleDrop(e, str);
                  }}
                  index={i}
                  color={themeColour}
                />
              ))}
            </Row>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};
