import React from "react";
import { useRecorder } from "../Handlers-Hooks/CreateAudio";
import { RecorderControls } from "./RecorderControls";
import { RecordingsList } from "./RecordingsList";
import "../../../../../main.css";
import type { RecordingListProps } from "../Interfaces";

export const VoiceRecorder = ({ userRecords }: RecordingListProps) => {
  const { recorderState, ...handlers } = useRecorder();
  const { audio } = recorderState;

  return (
    <>
      <RecorderControls recorderState={recorderState} handlers={handlers} />
      <RecordingsList
        audio={audio}
        userRecords={userRecords}
      />
    </>
  );
};
