import React, { useEffect, useState } from "react";
import { faHeart, faHeartBroken } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import type { ScoreProps } from "../FlipperInterfaces";

export const Score = ({
  gameEnd,
  incorrect,
  lives,
  score,
  total,
}: ScoreProps) => {
  const finalScore = incorrect + score;
  const remainder = total - score;
  const [lifeCount, setLifeCount] = useState(lives);
  const hearts: number[] = [];
  if (lives) {
    for (let i = 0; i < lives; i += 1) {
      hearts.push(i);
    }
  }

  useEffect(() => {
    if (incorrect > 0) {
      setLifeCount(lifeCount - 1);
    } else if (incorrect === 0) {
      setLifeCount(lives);
    }
  }, [incorrect]);

  const alive = (
    <FontAwesomeIcon
      className="flipper-heart"
      icon={faHeart as IconProp}
      style={{
        color: "#CC0A0D",
        fontSize: "2rem",
      }}
      data-testid="heart"
    />
  );

  const dead = (
    <FontAwesomeIcon
      shake
      style={{
        opacity: 0.1,
        scale: 1.225,
        animationDuration: "10s",
        fontSize: "1.75rem",
      }}
      icon={faHeartBroken as IconProp}
      data-testid="broken-heart"
    />
  );

  return (
    <div className="timer-container">
      {gameEnd ? (
        <div className="flipper-text-small">
          You took {finalScore} tries to make {total} matches.
        </div>
      ) : (
        <div className="flipper-text-small">
          You have {score}/{total} answers correct. {remainder} matches to go.
        </div>
      )}
      <div className="flipper-controls-container">
        {hearts.map((i) => (
          <div key={i}>{hearts[i] < lifeCount ? alive : dead}</div>
        ))}
      </div>
    </div>
  );
};
