/* eslint-disable jsx-a11y/media-has-caption*/

import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { faVolume, faVolumeSlash } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "react-bootstrap";
import "../../../../../main.css";

interface AudioButtonProps {
  audioURL: string;
  playIcon?: string;
  pauseIcon?: string;
  className?: string;
}

export const AudioPlayButton = ({
  audioURL,
  playIcon,
  pauseIcon,
  className,
}: AudioButtonProps) => {
  const [faPlayIcon, setPlayIcon] = useState<IconProp>(faPlay as IconProp);
  const [faPauseIcon, setPauseIcon] = useState<IconProp>(faPause as IconProp);
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    setPlayIcon((playIcon === "volume" ? faVolume : faPlay) as IconProp);
  }, [playIcon]);

  useEffect(() => {
    setPauseIcon(
      (pauseIcon === "volumeSlash" ? faVolumeSlash : faPause) as IconProp
    );
  }, [pauseIcon]);

  const playButton = (
    <FontAwesomeIcon icon={faPlayIcon as IconProp} data-testid="play-button" />
  );

  const pauseButton = (
    <FontAwesomeIcon
      icon={faPauseIcon as IconProp}
      data-testid="pause-button"
    />
  );

  const audioRef = useRef<HTMLAudioElement>(null);

  const onButtonClick = () => {
    if (audioRef.current) {
      if (!isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <>
      <audio
        controls={false}
        src={audioURL as string}
        ref={audioRef}
        onEnded={() => setIsPlaying(false)}
      />
      <Button
        bsPrefix="whakamaori-primary"     
        className={className}
        onClick={onButtonClick}
        title={isPlaying ? "Stop audio" : "Play audio"}
      >
        {isPlaying ? pauseButton : playButton}
      </Button>
    </>
  );
};

AudioPlayButton.defaultProps = {
  playIcon: "play",
  pauseIcon: "pause",
  className: "whakamaori-primary",
};
