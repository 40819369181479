/* eslint-disable no-await-in-loop */
export const tipuSet = async (t) => {
  let id = 0;
  const max = 10000;
  const imageArr = [""];
  let err;

  while (!err) {
    if (id >= max) {
      err = true;
    }
    const url = `https://media.tipu.plink.co.nz/tipu-tips/tipuTip${(id += 1)}.png`;
    const response = await fetch(url);

    if (response.ok) {
      const source: string = url as string;
      imageArr.push(source);

      if (t) {
        return imageArr;
      }
    }
    if (!response.ok) {
      err = true;
    }
  }
  imageArr.shift();

  return imageArr;
};
