/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import { AudioPlayButton } from "../AudioRecording/Components";
import type { QuestionInterface } from "./LessonProps";
import { t } from "../../../../services/translations/translations";

export const Question = ({
  currentQuestion,
  handleSubmit,
  correctCounter,
  incorrectCounter,
}: QuestionInterface) => {
  const [answer, setAnswer] = useState("");
  const [resultAnimation, setResult] = useState<Boolean | null>(null);
  const {
    pātaiKupu,
    pātaiLabel = "Pātai",
    pātaiMātātuhi,
    pātaiWhakarongo,
    whakautuLabel = "Whakautu",
    whakautu,
    themeColour,
  } = currentQuestion;

  const splitWhakautu = whakautu.split("_1_");
  const TIME_OUT = 500;
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(answer);
      setAnswer("");
    }
  };
  useEffect(() => {
    if (correctCounter) {
      setResult(true);
      const timer = setTimeout(() => setResult(null), TIME_OUT);

      return () => clearTimeout(timer);
    }
  }, [correctCounter]);
  useEffect(() => {
    if (incorrectCounter) {
      setResult(false);
      const timer = setTimeout(() => setResult(null), TIME_OUT);

      return () => clearTimeout(timer);
    }
  }, [incorrectCounter]);

  return (
    <Container
      fluid="x-lg"
      className="whakamaori-container"
      style={{ borderColor: themeColour }}
      data-testid="question-rows"
    >
      <Container fluid="lg" className="whakamaori-lesson-inner">
        <Row className="whakamaori-row">
          <h2 className="whakamaori-subheader">{pātaiLabel}</h2>
        </Row>
        {pātaiKupu}
        {pātaiMātātuhi && (
          <img
            className="whakamaori-image-question"
            src={pātaiMātātuhi}
            alt="pātai-mātātuhi"
          />
        )}
        {pātaiWhakarongo && (
          <Container className="whakamaori-whakarongo-question">
            <AudioPlayButton
              className="whakamaori-primary-audio-controls-large"
              audioURL={pātaiWhakarongo}
              playIcon="volume"
              pauseIcon="volumeSlash"
            />
          </Container>
        )}
        <Row className="whakamaori-row">
          <h2 className="whakamaori-subheader">{t(whakautuLabel)}</h2>
        </Row>
      </Container>
      <Row
        bsPrefix="whakamaori-row"
        style={{ backgroundColor: themeColour, borderColor: themeColour }}
      >
        <h2 className="whakamaori-subheader-mā-question">{splitWhakautu[0]}</h2>
        <input
          type="text"
          data-testid="answer-input"
          style={{ backgroundColor: themeColour }}
          className={
            resultAnimation
              ? "whakamaori-input-answer-correct-animation"
              : resultAnimation === null
              ? "whakamaori-input-answer"
              : "whakamaori-input-answer-incorrect-animation"
          }
          placeholder={t("answer")}
          value={answer || ""}
          onChange={(e) => {
            setAnswer(e.currentTarget.value);
          }}
          onKeyDown={(ev) => {
            handleKeyPress(ev);
          }}
        />
        <h2 className="whakamaori-subheader-mā-question">{splitWhakautu[1]}</h2>
      </Row>
      <Container fluid="x-lg">
        <Row className="whakamaori-row">
          <Col className="whakamaori-col">
            <Button
              bsPrefix="whakamaori-primary-submit"
              data-testid="submit-button"
              onClick={() => {
                handleSubmit(answer);
                setAnswer("");
              }}
            >
              {t("Submit")}
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
