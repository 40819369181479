/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import i18n from "i18next";

import { initReactI18next } from "react-i18next";
/* eslint-disable import/extensions */
import en from "./english.json";
import mri from "./te-reo.json";

enum Languages {
  English = "en",
  Maori = "mri",
}

const Namespace = 'taotrm-components';

function geti18n() {
  if (i18n.isInitialized){
    if (!i18n.hasResourceBundle(Languages.English, Namespace)){
      i18n.addResourceBundle(Languages.English, Namespace, en[Namespace]);
      i18n.addResourceBundle(Languages.Maori, Namespace, mri[Namespace]);
    }
  }else{
    const resources = {
      [Languages.English]: en,
      [Languages.Maori]: mri,
    };

    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources,
        lng: Languages.English,
        defaultNS: Namespace,
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      });
  }
  
  return i18n;
}

function t(val: string){
  return i18n.t(val, {ns: Namespace})
}

export { geti18n, t, Languages };