/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/pro-regular-svg-icons";
import { motion } from "framer-motion";
import { Question } from "./Question";
import { EndLesson } from "./EndLesson";
import "../../../../main.css";
import type { QuestionOptions, LessonInterface } from "./LessonProps";
import { ExternalContext } from "../External";
import { DraggableQuestion } from "./DragComponents/DraggableQuestions";
import { ToggleButton } from "./Toggle";
import { shuffle } from "./DragComponents/ShuffleArr";
import { t } from "../../../../services/translations/translations";

export const Lesson = ({ lessons, haltAdvance = false }: LessonInterface) => {
  const [completed, setCompleted] = useState(false);
  const [current, setCurrent] = useState(0);
  const [question, setQuestion] = useState<QuestionOptions>(lessons[current]);
  const [answerArray, setAnswerArray] = useState<
    { answersGiven: string; answersCorrect: boolean }[]
  >([]);
  const [allAnswers, setAllAnswers] = useState<string[]>([]);
  const [score, setScore] = useState(0);
  const [correctCount, updateCorrectCount] = useState(0);
  const [incorrectCount, updateIncorrectCount] = useState(0);
  const { themeColour } = question;
  const callbackContext = useContext(ExternalContext);
  useEffect(() => {
    if (current < lessons.length) {
      setQuestion(lessons[current]);
    }
    if (current >= lessons.length) {
      if (callbackContext) {
        callbackContext.questionCallback(answerArray || []);
      }
      setCompleted(true);
    }
  }, [current]);

  const allAnswersArray: string[] = [];
  useEffect(() => {
    if (lessons) {
      for (const answerKey of lessons) {
        for (const oneArray of answerKey.whakautuKey) {
          for (const tuwahi of oneArray) {
            allAnswersArray.push(tuwahi as string);
          }
        }
      }
    }
    const filterRepeatAnswers = Array.from(
      new Set(allAnswersArray.map((entry) => entry as string))
    );
    const shuffleArr = shuffle(filterRepeatAnswers);
    setAllAnswers(shuffleArr);
  }, []);

  const handleClick = (answerInput: string) => {
    const matchAnswers = question.whakautuKey[0].filter(
      (storedAnswers) => answerInput === storedAnswers
    );
    const answersCorrect = matchAnswers.length > 0;
    setAllAnswers(shuffle(allAnswers));

    if (question) {
      setAnswerArray((prev) => [
        ...prev,
        {
          answersGiven: answerInput,
          answersCorrect,
        },
      ]);
    }
    if (answersCorrect) {
      setCurrent(current + 1);
      setScore(score + 1);
      updateCorrectCount(correctCount + 1);
    } else {
      updateIncorrectCount(incorrectCount + 1);
      if (!haltAdvance) {
        setCurrent(current + 1);
      }
    }
  };

  const resetLesson = () => {
    setAnswerArray([{ answersGiven: "", answersCorrect: false }]);
    setCompleted(false);
    setCurrent(0);
    setScore(0);
    updateCorrectCount(0);
    updateIncorrectCount(0);
  };
  const [toggleOpen, setIsToggleOpen] = useState(true);

  const toggle = () => {
    setIsToggleOpen(!toggleOpen);
  };

  return (
    <Container
      fluid="x-lg"
      className="whakamaori-lesson-container"
      style={{ borderColor: themeColour, paddingBottom: "1%" }}
    >
      {completed ? (
        <>
          <EndLesson />
          {haltAdvance && incorrectCount !== 0 && (
            <Row className="whakamaori-row">
              {t("You took")} {incorrectCount + correctCount}{" "}
              {t("tries to answer")} {lessons.length} {t("questions.")}
            </Row>
          )}
          {haltAdvance && incorrectCount === 0 && (
            <Row className="whakamaori-row">
              {t("You got all ")}
              {lessons.length}
              {t(" questions right the first time!")}
            </Row>
          )}
          {!haltAdvance && (
            <Row className="whakamaori-row">
              {t("You scored")} {score} {t("out of")} {lessons.length}
            </Row>
          )}
          <Row className="whakamaori-row">
            <Col className="whakamaori-col">
              <Button
                bsPrefix="whakamaori-primary-refresh"
                data-testid="restart-button"
                onClick={resetLesson}
              >
                <FontAwesomeIcon icon={faRotateLeft} />
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Container
            fluid="x-lg"
            className="whakamaori-h-container"
            style={{
              borderColor: themeColour,
              borderBottom: `${themeColour} + "2px solid "`,
              backgroundColor: themeColour,
            }}
          >
            <h1 className="whakamaori-title-mā">{t("Questions")}</h1>
          </Container>
          <motion.div>
            <ToggleButton
              toggleWidget={toggle}
              moduleState={toggleOpen}
              themeColour={question.themeColour}
            />
            {toggleOpen && (
              <DraggableQuestion
                currentQuestion={question}
                answerOptions={allAnswers}
                handleSubmit={handleClick}
                correctCounter={correctCount}
                incorrectCounter={incorrectCount}
              />
            )}
            {!toggleOpen && (
              <Question
                currentQuestion={question}
                handleSubmit={handleClick}
                correctCounter={correctCount}
                incorrectCounter={incorrectCount}
              />
            )}
          </motion.div>

          <Col
            className="whakamaori-row"
            style={{ backgroundColor: "#f6f6f6", margin: "none" }}
          >
            {!completed && (
              <p
                data-testid={`${current}-length`}
                style={{ paddingTop: "1rem" }}
              >
                {t("Question")} {current + 1} {t("of")} {lessons.length}
              </p>
            )}
          </Col>
        </>
      )}
    </Container>
  );
};
