import React, { useEffect, useMemo } from "react";
import "../../main.css";
import { Col, Container } from "react-bootstrap";
import { Lesson } from "./Utils/Lesson/Lesson";
import { VoiceRecorder } from "./Utils/AudioRecording/Components/VoiceRecorder";
import { ExternalContext } from "./Utils/External";
import type { WhakamāoriProps } from "./Interfaces/WhakamāoriProps";
import { RenderMaoriText } from "./Utils/RenderText";
import { geti18n } from "../../services/translations/translations" 

export const WhakaMāori = ({
  audioURL,
  advanceOnCorrect = false,
  lessons,
  title,
  titleTranslation,
  māoriText,
  pākehāText,
  voiceRecorder,
  records,
  audioCallback,
  questionCallback,
  displayLanguage
 
}: WhakamāoriProps) => {
  const enableVoiceRecorder = () => {
    if (voiceRecorder)
      return <VoiceRecorder userRecords={records} audio={null} />;
  };
  const enableLesson = () => {
    if (lessons && lessons.length > 0) {
      return <Lesson lessons={lessons} haltAdvance={advanceOnCorrect} />;
    }
  };

  useEffect(()=>{
    const i18n = geti18n();
    i18n.changeLanguage(displayLanguage);
  },[displayLanguage])
  
  const getContext = useMemo(() => ({ audioCallback, questionCallback }), []);

  return (
    <ExternalContext.Provider value={getContext}>
      <Container fluid="x-lg" className="whakamaori">
        <Container fluid="x-lg">
          <Col lg className="whakamaori-h-container">
            <h1 className="whakamaori-title">{title}</h1>
            <h3 className="whakamaori-subheader">{titleTranslation}</h3>
          </Col>
          <Container fluid="x-lg" className="whakamaori-b-container">
            <RenderMaoriText
              māoriText={māoriText}
              pākehāText={pākehāText}
              audioURL={audioURL}
            />
            {enableVoiceRecorder()}
          </Container>
        </Container>
        {enableLesson()}
      </Container>
    </ExternalContext.Provider>
  );
};

WhakaMāori.defaultProps = {
  title: "Kia ora ao hangarau",
  titleTranslation: "Hello World",
};
