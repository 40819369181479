/* eslint-disable react/no-array-index-key */
import React from 'react';
import type { CellLocation } from '../Interfaces';
import { Cell } from './Cell';

interface RowProps {
  row: string[]
  rowIndex: number
  hintCell: CellLocation
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onMouseDown: (e: any, row: number, col: number) => void;
  onMouseMove: (row: number, col: number) => void;
  onMouseUp: () => void;
}
export const Row = ({ row, rowIndex, hintCell, onMouseDown, onMouseMove, onMouseUp }: RowProps) => (
  <tr>
    {row.map((cell, i) => (
      <Cell key={i} rowIndex={rowIndex} colIndex={i} hintCell={hintCell}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >{cell}</Cell>
    ))}
  </tr>
);
