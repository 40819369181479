/* eslint-disable no-console */
import React, { useCallback } from "react";
import { WhakaMāori } from "./Components/WhakaMāori/WhakaMāori";
import { Pangakupu } from "./Components/Pangakupu/Pangakupu";
import { prevRecords } from "./Components/WhakaMāori/Assets/TestData/prevRecords";
import { TipuSwipe } from "./Components/TipuTips/TipuMain";
import { KupuOTeRa } from "./Components/KupuOfTheDay/KupuHead";
import basic from "./Components/Pangakupu/Assets/crosswordLayoutBasic.json";
import { TuwahiAnswers } from "./Components/WhakaMāori/Assets/TestData/TuwahiAnswers";
import WhakatakaTeHau from "./Components/WhakaMāori/Assets/TestData/4.json";
import basicFlipper from "./Components/Flipper/Assets/vocab/test.json";
import wordsearchGrid from "./Components/KupuPorehu/Assets/test1Grid.json";
import wordsearchWords from "./Components/KupuPorehu/Assets/test1Words.json";
import { Flipper } from "./Components/Flipper/Flipper";
import { KupuPorehu } from "./Components/KupuPorehu/KupuPorehu";

export const App = () => (
  <>
    <KupuPorehu wordFind={wordsearchGrid} wordList={wordsearchWords} />
    <Flipper
      data={basicFlipper}
      autoSelect
      showMaori={false}
      showEnglish={false}
      lives={15}
    />

    <WhakaMāori
      title={WhakatakaTeHau.name}
      titleTranslation={WhakatakaTeHau.nameTranslation}
      audioURL={WhakatakaTeHau.audio}
      māoriText={WhakatakaTeHau.māori}
      pākehāText={WhakatakaTeHau.pākehā}
      voiceRecorder
      records={prevRecords}
      lessons={TuwahiAnswers}
      audioCallback={useCallback(
        (onAudioRecordingChange) =>
          console.log("audio", onAudioRecordingChange),
        []
      )}
      questionCallback={useCallback((onLessonComplete) => {
        console.log("lesson complete", onLessonComplete);
      }, [])}
      advanceOnCorrect
      displayLanguage="en"
    />
    <TipuSwipe />
    <KupuOTeRa />
    <Pangakupu data={basic} />
  </>
);
