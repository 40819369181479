import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { t } from "../../../services/translations/translations";

export const Rules = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        bsPrefix="flipper-button-rules"
        data-testid="rules-button"
        onClick={handleShow}
      >
        Rules
      </Button>

      <Modal show={show} onHide={handleClose} data-testid="rules-modal">
        <Modal.Header closeButton>
          <Modal.Title>{t("Flipper Rules")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            "To win Flipper match all the Māori words with their English translation before your lives run out!"
          )}
        </Modal.Body>
        <Modal.Body>
          {t(
            `Click on a card to select or reveal the word. Find the matching word to reveal the next card. When all cards have been matched, you have won!`
          )}
        </Modal.Body>
        <Modal.Body>{t("Click the restart button to play again.")}</Modal.Body>
      </Modal>
    </>
  );
};
