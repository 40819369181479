import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../../../main.css";
import { t } from "../../../../services/translations/translations";

export const EndLesson = () => (
  <Container fluid="lg">
    <Row className="whakamaori-row">
      <Col
        className="whakamaori-col-complete-label"
        data-testid="end-lesson-banner"
      >
        Ka pai! {t("You completed the lesson")}.
      </Col>
    </Row>
  </Container>
);