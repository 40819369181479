/* eslint-disable no-console */
import React from "react";
import type { AudioOptions } from "./AudioRecording/Interfaces";
import type { LessonComplete } from "./Lesson/LessonProps";

export interface ExternalInterface {
  audioCallback: (onAudioRecordingChange: AudioOptions[]) => void;
  questionCallback: (onLessonComplete: LessonComplete[]) => void;
}

export const ExternalContext = React.createContext<ExternalInterface | null>(
  null
);
