import React from "react";
import { motion } from "framer-motion";
import "../../../../main.css";

interface ToggleInt {
  moduleState: boolean;
  toggleWidget: () => void;
  themeColour: string;
}

export const ToggleButton = ({
  toggleWidget,
  moduleState,
  themeColour,
}: ToggleInt) => {
  const toggleContainer = `whakamaori-toggle ${moduleState ? "drag" : "input"}`;
  const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30,
  };

  const toggleBackground = moduleState ? `rgba(16,68,71,28) ` : themeColour;
  const imageSrc = moduleState
    ? `https://assets.tereomaori.nz/images/taotrLogo.png`
    : `https://assets.tereomaori.nz/images/taotrLogoBW.png`;

  return (
    <motion.div
      animate
      className={toggleContainer}
      style={{ backgroundColor: toggleBackground }}
      onClick={toggleWidget}
    >
      <motion.div
        layout
        onClick={toggleWidget}
        transition={spring}
        data-testid="toggle-button"
      >
        <img
          className="whakamaori-toggle-img"
          src={imageSrc}
          alt="toggle question mode"
        />
      </motion.div>
    </motion.div>
  );
};
