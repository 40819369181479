import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { formatDistanceToNow } from "date-fns";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Container, Row } from "react-bootstrap";
import { generateKey } from "../Handlers-Hooks/GenerateKey";
import type {
  AudioOptions,
  RecordingListProps,
} from "../Interfaces/RecorderControlProps";
import { AudioPlayButton } from "./AudioPlayButton";
import { ExternalContext } from "../../External";
import "../../../../../main.css";

export const RecordingsList = ({ audio, userRecords }: RecordingListProps) => {
  const callbackContext = useContext(ExternalContext);
  const [records, setRecords] = useState<AudioOptions[]>([]);
  const [displayRecords, setDisplayRecords] = useState<AudioOptions[]>([]);

  useEffect(() => {
    const recordings: AudioOptions[] =
      userRecords ? userRecords.filter(recording => recording.audio)  : [];
    setRecords(recordings);
  }, [userRecords]);
  
  useEffect(() => {
    if (audio){
      const recordings: AudioOptions[] =
        records ? records.filter(recording => recording.audio)  : [];
      
      recordings.push({
        id: null,
        dateTime: generateKey(),
        audio,
        displayDate: "",
      });
      
      setRecords(recordings);
    }
  }, [audio]);
  
  useEffect(() => {
    if (callbackContext){
      callbackContext.audioCallback(records);
    }
    if (records) {
      const tmpRecordings = [...records];
      const newArr = tmpRecordings.map((record) => {
        const tmpResult = { ...record };
        const dateTimeNumber = Number.parseInt(tmpResult.dateTime, 10);
        const date = new Date(dateTimeNumber);
        const distanceDate = formatDistanceToNow(date);
        tmpResult.displayDate = `${distanceDate} ago`;

        return {
          audio: record.audio,
          dateTime: record.dateTime,
          displayDate: tmpResult.displayDate,
          id: record.id,
        };
      });
      newArr.sort(
        (a, b) =>
          Number.parseInt(b.dateTime, 10) - Number.parseInt(a.dateTime, 10)
      );
      setDisplayRecords(newArr);
    }
  }, [records]);
  
  const deleteAudio = (key: string) => {
    const newRecords = records.filter((rec) => rec.dateTime !== key);
    setRecords(newRecords);
  };

  return (
    <Container fluid="x-lg" className="whakamaori-recording-container">
      {displayRecords.length > 0 ? (
        <Container fluid="lg">
          <Row className="whakamaori-row">Your recordings</Row>
          {displayRecords.map((record) => (
            <Row
              className="whakamaori-row-coloured"
              key={record.dateTime as string}
            >
              {!!record.audio && (
                <Col
                  className="whakamaori-col-coloured"
                  key={record.dateTime as string}
                  title={record.audio as string}
                  data-testid="audioURL"
                >
                  <p className="whakamaori-row-record-list">
                    {record.displayDate}
                  </p>
                  <Col md={1} className="whakamaori-col-button">
                    <AudioPlayButton audioURL={record.audio as string} />
                    <Button
                      bsPrefix="whakamaori-primary"
                      data-testid="delete-button"
                      type="button"
                      title="Delete this recording.·"
                      onClick={() => {
                        deleteAudio(record.dateTime);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash as IconProp} />
                    </Button>
                  </Col>
                </Col>
              )}
            </Row>
          ))}
        </Container>
      ) : (
        <Container data-testid="no-recordings" style={{ display: "none" }} />
      )}
    </Container>
  );
};
