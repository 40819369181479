import React, { useState } from 'react';
import { Grid } from '../Grid/Grid';
import { Words } from '../Words/Words';
import type { CellLocation, GridInterface, WordsInterface } from '../Interfaces';
import '../../../main.css';

interface GameProps {
  wordsTest: WordsInterface[],
  grid: GridInterface
}

export const Game = ({ wordsTest, grid }: GameProps) => {
  const [words, setWords] = useState<WordsInterface[]>(wordsTest);
  const [hintCell, setHintCell] = useState<CellLocation>({ row: -1, col: -1 });

  const onWordSelected = (word: WordsInterface | undefined) => {
    if (word?.fromCell) {
      setHintCell(word.fromCell)
    }
    else {
      setHintCell({ row: -1, col: -1 });
    }
  }

  return (
    <div className='porehu-game-wrapper'>
      <Grid
        grid={grid}
        words={words}
        setWords={setWords}
        hintCell={hintCell} />
      <Words words={words} onWordSelected={onWordSelected} />
    </div>
  );
};
