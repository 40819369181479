export const TuwahiAnswers = [
  {
    pātaiLabel: "He aha te kupu?",
    pātaiId: 1,
    whakautuLabel: "Whakautu",
    // pātaiMātātuhi: "https://assets.tereomaori.nz/images/roto.png",
    pātaiWhakarongo:
      "https://inspiringcommunities.org.nz/wp-content/uploads/2019/03/3.-Whakataka-te-Hau-Karakia-timatanga.mp3",
    whakautu: "Ko _1_ te kupu ",
    whakautuKey: [["roto", " mea"]],
    themeColour: "#2E727B",
  },
  {
    pātaiLabel: "Kei hea te porohita?",
    pātaiId: 2,
    whakautuLabel: "Whakautu",
    pātaiMātātuhi: "https://assets.tereomaori.nz/images/mua.png",
    // pātaiWhakarongo:
    //   "https://inspiringcommunities.org.nz/wp-content/uploads/2019/03/3.-Whakataka-te-Hau-Karakia-timatanga.mp3",
    whakautu: "Kei _1_ te porowhita i te poaka",
    whakautuKey: [["mua", "muri", "waho"]],
    themeColour: "#2E727B",
  },
  {
    pātaiLabel: "Kei hea te porohita?",
    pātaiId: 3,
    whakautuLabel: "Whakautu",
    pātaiMātātuhi: "https://assets.tereomaori.nz/images/muri.png",
    whakautu: "Kei _1_ te porowhita i te pouaka.",
    whakautuKey: [["muri", "waho", "mua"]],
    themeColour: "#2E727B",
  },
  {
    pātaiLabel: "Kei hea te porohita?",
    pātaiId: 4,
    whakautuLabel: "Whakautu",
    pātaiMātātuhi: "https://assets.tereomaori.nz/images/raro.png",
    whakautu: "Kei _1_ te porowhita i te pouaka.",
    whakautuKey: [["raro", "waho"]],
    themeColour: "#2E727B",
  },
  {
    pātaiLabel: "Kei hea te porohita?",
    pātaiId: 5,
    whakautuLabel: "Whakautu",
    pātaiMātātuhi: "https://assets.tereomaori.nz/images/runga.png",
    whakautu: "Kei _1_ te porowhita i te pouaka.",
    whakautuKey: [["waho", "runga", "rāwaho"]],
    themeColour: "#2E727B",
  },
  {
    pātaiLabel: "Kei hea te porohita?",
    pātaiId: 6,
    whakautuLabel: "Whakautu",
    pātaiMātātuhi: "https://assets.tereomaori.nz/images/roto.png",
    whakautu: "Kei _1_ te porowhita i te pouaka.",
    whakautuKey: [["roto", "waenganui"]],
    themeColour: "#2E727B",
  },
  {
    pātaiLabel: "Kei hea te porohita?",
    pātaiId: 7,
    whakautuLabel: "Whakautu",
    pātaiMātātuhi: "https://assets.tereomaori.nz/images/raro.png",
    whakautu: "Kei _1_ te porowhita i te pouaka.",
    whakautuKey: [["raro", "waho", "mōwaho"]],
    themeColour: "#2E727B",
  },
];
