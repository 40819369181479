import React from "react";
import { Game } from "./Game/Game";
import type { GridInterface, KupuPorehuProps } from "./Interfaces";

import "../../main.css";

export const KupuPorehu = ({ wordFind, wordList }: KupuPorehuProps) => {
  let length = 0;
  const quoted = wordFind.map((row) => {
    length += row.length;

    return row.split("");
  });
  if (!quoted || quoted.length < 1) {
    throw Error("The data for the wordsearch is invalid");
  }
  const width = quoted[0].length;

  const gi: GridInterface = {
    width,
    size: length,
    gridIn2D: quoted,
  };

  return <Game wordsTest={wordList} grid={gi} />;
};
