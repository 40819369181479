export const shuffle = (arr: string[]) => {
  let m = arr.length;
  const newArr: string[] = [];
  let t;

  while (m) {
    t = Math.floor(Math.random() * m);
    newArr.push(arr.splice(t, 1)[0]);

    m -= 1;
  }

  return newArr;
};
