import type { CardData } from "../Flipper";

export const compareAlphabetical = (a: CardData, b: CardData) => {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }

  return 0;
};
