import React, { useEffect, useRef, useState } from "react";
import type {
  CluesInput,
  CrosswordProviderImperative,
} from "@jaredreisinger/react-crossword";
import {
  CrosswordProvider,
  DirectionClues,
  CrosswordGrid,
} from "@jaredreisinger/react-crossword";
import "../../main.css";
import { t } from "../../services/translations/translations";

export const Pangakupu = ({ data }: { data: CluesInput }) => {
  const crosswordRef = useRef<CrosswordProviderImperative>(null);
  const testTheme = {
    columnBreakpoint: "850px",
    focusBackground: "rgb(86, 176, 176)",
    numberColor: "rgb(27, 116, 122)",
    highlightBackground: "rgb(86, 176, 176)",
    gridBackground: "rgb(27, 116, 122)",
    cellBorder: "rgb(27, 116, 122)",
  };

  const [upperCaseData, setUpperCaseData] = useState<CluesInput>();
  const [storageKey, setStorageKey] = useState("");
  const [storeData, setStoreData] = useState(true);
  const makeUpperCase = (dataAnyCase: CluesInput) => {
    const across = Object.keys(dataAnyCase.across).reduce(
      (accumulator, val) => ({
        ...accumulator,
        [val]: {
          ...dataAnyCase.across[val],
          answer: dataAnyCase.across[val].answer.toUpperCase(),
        },
      }),
      {}
    );

    const down = Object.keys(dataAnyCase.down).reduce(
      (accumulator, val) => ({
        ...accumulator,
        [val]: {
          ...dataAnyCase.down[val],
          answer: dataAnyCase.down[val].answer.toUpperCase(),
        },
      }),
      {}
    );
    const dataUpper = { across, down };

    return dataUpper;
  };
  const handleStoreData = () => {
    setStoreData(!storeData);
  };
  useEffect(() => {
    setUpperCaseData(makeUpperCase(data));
    setStorageKey(JSON.stringify(data.down));
  }, [data]);

  if (upperCaseData) {
    return (
      <CrosswordProvider
        data={upperCaseData}
        ref={crosswordRef}
        storageKey={storageKey}
        useStorage={storeData}
      >
        <div className="crossword-container">
          <div className="crossword grid" data-testid="grid">
            <CrosswordGrid theme={testTheme} />
          </div>
          <div className="crossword-clue">
            <div className="crossword-clue-container">
              <DirectionClues direction="across" />
            </div>
            <div className="crossword-clue-container">
              <DirectionClues direction="down" />
            </div>
          </div>
        </div>
        <div className="crossword-reset-container">
          <button
            className="crossword-reset-btn"
            type="button"
            onClick={handleStoreData}
          >
            {storeData ? t("Clear") : t("Restore")}
          </button>
        </div>
      </CrosswordProvider>
    );
  }

  return null;
};
