/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faColumns,
  faEyeSlash,
  faEye,
} from "@fortawesome/pro-regular-svg-icons";
import type { RenderMāoriText } from "./RenderMāoriTextProps";
import "../../../../main.css";
import { AudioPlayButton } from "../AudioRecording/Components/AudioPlayButton";

export const RenderMaoriText = ({
  audioURL,
  māoriText,
  pākehāText,
}: RenderMāoriText) => {
  const [audioLinkList, setAudioList] = useState<string[]>([]);
  useEffect(() => {
    if (audioURL && typeof audioURL === "string") {
      setAudioList(() => [audioURL]);
    } else if (audioURL && typeof audioURL !== "string") {
      setAudioList(() => [...audioURL]);
    }
  }, [audioURL, māoriText]);

  const [current, setCurrent] = useState(0);
  const [visibleCol, setVisible] = useState<boolean | undefined>();
  const [toggleButtonVisible, setToggleButtonVisible] = useState(true);
  const toggleVisible = () => setVisible(!visibleCol);
  const tableRows: {
    māori: string;
    pākehā: string;
    audio: string;
    visible: boolean;
    key: string;
  }[] = [];

  const [rows, setRows] = useState(tableRows);

  useEffect(() => {
    for (let i = 0; i < māoriText.length; i += 1) {
      tableRows.push({
        māori: māoriText[i],
        pākehā: pākehāText[i] || "",
        audio: audioLinkList[i],
        visible: true,
        key: i.toString(),
      });
    }
    setRows(tableRows);
  }, [audioLinkList, māoriText]);

  useEffect(() => {
    setVisible(!!pākehāText.length);
    setToggleButtonVisible(!!pākehāText.length);
  }, [māoriText]);

  useEffect(() => {
    setVisible(!!pākehāText.length);
    setToggleButtonVisible(!!pākehāText.length);
  }, [rows]);

  const toggleRow = (event) => {
    const tempRows = rows;
    const btn = event.currentTarget as Element;
    const btnID = btn.id as string;
    tempRows[btnID].visible = !tempRows[btnID].visible;
    setRows(tempRows);

    setCurrent(current + 1);
  };

  const eye = (
    <FontAwesomeIcon
      icon={faEye as IconProp}
      data-testid="row-visible-button"
    />
  );
  const eyeSlash = (
    <FontAwesomeIcon
      icon={faEyeSlash as IconProp}
      data-testid="row-visible-button"
    />
  );
  const faColumnIcon = faColumns as IconProp;
  const HALF_COL_WIDTH = 5;
  const FULL_COL_WIDTH = 10;

  return (
    <>
      <Container fluid="lg" data-testid="text-table">
        {rows.map((whiti) => (
          <Row key={whiti.key} className="whakamaori-row-coloured">
            <Col xs={2} className="whakamaori-col-coloured">
              {audioLinkList.length > 1 ? (
                <>
                  <AudioPlayButton
                    audioURL={whiti.audio as string}
                    playIcon="volume"
                    pauseIcon="volumeSlash"
                  />
                  <Button
                    bsPrefix="whakamaori-primary"
                    variant="visible"
                    data-testid="toggle-row"
                    title="Show/Hide Row"
                    onClick={toggleRow}
                    id={whiti.key}
                  >
                    {whiti.visible ? eyeSlash : eye}
                  </Button>
                </>
              ) : (
                <Button
                  bsPrefix="whakamaori-primary"
                  variant="visible"
                  data-testid="toggle-row"
                  title="Show/Hide Row"
                  onClick={toggleRow}
                  id={whiti.key}
                >
                  {whiti.visible ? eyeSlash : eye}
                </Button>
              )}
            </Col>
            <Col
              xs={visibleCol ? HALF_COL_WIDTH : FULL_COL_WIDTH}
              className="whakamaori-col-coloured"
              data-testid="māori-col"
            >
              {visibleCol !== undefined && whiti.visible && whiti.māori}
            </Col>
            {visibleCol && (
              <Col
                xs={5}
                className="whakamaori-col-coloured"
                data-testid="pākehā-col"
              >
                {visibleCol !== undefined && whiti.visible && whiti.pākehā}
              </Col>
            )}
          </Row>
        ))}
      </Container>
      <Container fluid="lg" data-testid="audio-player-columns-container">
        <Row bsPrefix="whakamaori-row-right">
          {(toggleButtonVisible || audioLinkList.length === 1) && (
            <Col className="whakamaori-col-right">
              {audioLinkList.length === 1 && (
                <AudioPlayButton
                  audioURL={audioLinkList[0]}
                  playIcon="play"
                  pauseIcon="pause"
                />
              )}
              {toggleButtonVisible && (
                <Button
                  bsPrefix="whakamaori-primary"
                  variant="columns"
                  data-testid="toggle-column"
                  title={visibleCol ? "Hide Pakehā Text" : "Show Pākehā Text"}
                  onClick={() => {
                    toggleVisible();
                  }}
                >
                  <FontAwesomeIcon icon={faColumnIcon} />
                </Button>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};
