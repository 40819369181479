import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { motion, useAnimationControls } from "framer-motion";
import type { AnswerBubbleInt, AnswerBoxInt } from "../LessonProps";

export const AnswerBubble = ({
  answer,
  color,
  index,
  isSelected,
  onSelect,
}: AnswerBubbleInt) => {
  const [answerSelect, setAnswerSelect] = useState(isSelected);
  const handleDragEnd = (e) => {
    setAnswerSelect(!answerSelect);
    const { pageX: x, pageY: y } = e as MouseEvent;
    onSelect({ x, y }, answer);
  };

  return (
    <Col className="whakamaori-col-bubble">
      <motion.button
        className="whakamaori-primary-bubble"
        data-testid="draggable-answer-input"
        initial={false}
        key={index}
        whileHover={{ scale: 1.2, cursor: "pointer" }}
        whileTap={{ scale: 1.2, cursor: "grabbing" }}
        drag
        dragElastic={1}
        dragSnapToOrigin
        dragMomentum={false}
        onDragEnd={handleDragEnd}
        style={{
          color
        }}
      >
        {answer}
      </motion.button>
    </Col>
  );
};

export const AnswerBox = ({ location, animation }: AnswerBoxInt) => {
  const reference = useRef<HTMLButtonElement>(null);
  const controls = useAnimationControls();
  useEffect(() => {
    if (animation) {
      controls.start({
        backgroundColor: "#1b7a4e",
        border: "#1b7a4e",
        animationName: "resultAnimation",
        animationDuration: " 4.5s",
      });
    }
    if (animation === false) {
      controls.start({
        backgroundColor: "#e61919",
        border: "#e61919",
        animationName: "resultAnimation",
        animationDuration: " 4.5s",
      });
    }
    if (animation === null) {
      controls.set({ backgroundColor: "#f6f2e9", border: "#f6f6f6" });
    }
  }, [animation]);

  useEffect(() => {
    const { current } = reference;
    if (current) {
      const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = current;
      const locationParam = {
        x: { min: offsetLeft, max: offsetLeft + offsetWidth },
        y: { min: offsetTop, max: offsetTop + offsetHeight },
      };
      location(locationParam);
    }
  }, [reference.current?.offsetTop, reference.current?.offsetLeft]);

  return (
    <motion.button
      ref={reference}
      animate={controls}
      className="whakamaori-primary-answer"
      data-testid="answer-input-box"
    />
  );
};
