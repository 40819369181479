/* eslint-disable @typescript-eslint/no-unsafe-return */
import type { CardData } from "../Flipper";

export const shuffle = (arrayExisting: CardData[]) => {
  const array = arrayExisting.slice();
  let currentIndex = array.length;
  while (currentIndex !== 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};
