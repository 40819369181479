import React, { useEffect, useState } from "react";
import { XMLParser } from "fast-xml-parser";
import type { Kupu } from "./Kupu Interfaces/Kupu";
import type { Item } from "./Kupu Interfaces/Item";
import type { KupuItemInterface } from "./Kupu Interfaces/KupuItemInterface";
import "../../main.css";

const KupuItemDesc = ({ value }: KupuItemInterface) => (
  /* eslint-disable react/no-danger */
  <div dangerouslySetInnerHTML={{ __html: value }} />
);
const KupuItemTitle = ({ value }: KupuItemInterface) => (
  /* eslint-disable react/no-danger */
  <div dangerouslySetInnerHTML={{ __html: value }} />
);
const KupuItemDate = ({ value }: KupuItemInterface) => (
  /* eslint-disable react/no-danger */
  <div dangerouslySetInnerHTML={{ __html: value }} />
);

export const ShowKupu = () => {
  const [error, setError] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState<Item[]>([]);
  const [current, setCurrent] = useState(0);
  const url = "https://assets.tereomaori.nz/kupuOTeRa.xml";
  const parser = new XMLParser();

  const fetchKupu = async () => {
    const response = await fetch(url);
    const result = await response.text();
    const xmlRes = parser.parse(result) as Kupu;

    if (!response.ok) {
      setError(response.statusText);
    }
    if (response.ok) {
      const parseRes = xmlRes;
      setItems(parseRes.rss.channel.item as Item[]);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    fetchKupu();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!isLoaded || items.length === 0) {
    return (
      <div>
        <p>Loading..</p>
      </div>
    );
  }
  const item = items[current];

  const replaceShowKupu = "ShowKupu";
  const fixLink = item.link.replace(replaceShowKupu, "PDF");
  const audioMap = item.title;
  const audioFixGapReg = / /g;
  const audioFix = audioMap.replace(audioFixGapReg, "-");
  const audioLink = `https://kupu.maori.nz/blobs/Audio/${audioFix}.mp3`;
  const kupuTitle = (
    <KupuItemTitle key={item.title} id={item.title} value={item.title} />
  );

  const kupuDesc = (
    <KupuItemDesc
      key={item.description}
      id={item.description}
      value={item.description}
    />
  );
  const kupuDate = (
    <KupuItemDate key={item.pubDate} id={item.pubDate} value={item.pubDate} />
  );

  return (
    <div className="putiputi">
      <button
        data-testid="back-button"
        type="button"
        className="navbutton"
        onClick={() => setCurrent(current + 1)}
        disabled={current === items.length - 1}
      >
        &lt;
      </button>
      <button
        type="button"
        data-testid="today-button"
        className="navbutton"
        onClick={() => setCurrent(0)}
      >
        Today&apos;s Kupu
      </button>
      <button
        data-testid="fwd-button"
        className="navbutton"
        type="button"
        onClick={() => {
          setCurrent(current - 1);
        }}
        disabled={current === 0}
      >
        &gt;
      </button>
      <h1 className="putiputi-header">{kupuTitle}</h1>
      <div className="putiputi-description">{kupuDesc}</div>
      <br />
      <div className="putiputi-pubdate">{kupuDate}</div>
      <figure>
        <figcaption>Listen to {kupuTitle}</figcaption>
        <audio controls src={audioLink} style={{ maxWidth: "90%" }}>
          <track kind="captions" label="Māori" />
        </audio>
      </figure>
      <div>
        <div>
          <button type="button" className="navbutton">
            <a href={fixLink} target="_blank" rel="noreferrer">
              Download PDF
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};
