import React from "react";
import { ShowKupu } from "./ShowKupu";
import "../../main.css";

export const KupuOTeRa = () => (
  <>
    <div data-testid="kupu-head" className="kupu">
      <div>
        <h1 data-testid="kupu-header" className="kupu-header">
          Kupu o te Rā
        </h1>
        <h2 data-testid="kupu-subheader" className="kupu-subheader">
          Today&apos;s kupu:
        </h2>
      </div>
    </div>
    <ShowKupu />
  </>
);
