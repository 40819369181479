import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSquareFull, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Container, Col, Row } from "react-bootstrap";
// import { formatSeconds, formatMinutes } from "../Handlers-Hooks/FormatTime";
import type { RecorderControlsProps } from "../Interfaces/RecorderControlProps";
import { t } from "../../../../../services/translations/translations";
import "../../../../../main.css";

const faSquarePropIcon = faSquareFull as IconProp;
const faDotCirclePropIcon = faDotCircle as IconProp;

export const RecorderControls = ({
  recorderState,
  handlers,
}: RecorderControlsProps) => {
  const { initRecording } = recorderState;
  const { startRecording, saveRecording } = handlers;

  return (
    <Container fluid="md" className="whakamaori-recording">
      <Row className="whakamaori-row">
        <Col lg="1" className="whakamaori-col">
          <Button
            data-testid="record-button"
            type="button"
            bsPrefix="whakamaori-primary"
            variant={initRecording ? "record-start" : "record"}
            title={initRecording ? "Save recording" : "Start recording"}
            onClick={() => (initRecording ? saveRecording() : startRecording())}
          >
            <FontAwesomeIcon
              icon={initRecording ? faSquarePropIcon : faDotCirclePropIcon}
              className={
                initRecording
                  ? "whakamaori-primary-recording"
                  : "whakamaori-primary-record"
              }
            />
          </Button>
        </Col>
        <Col lg="1" className="whakamaori-col">
          {t("Talk")}
        </Col>
      </Row>
    </Container>
  );
};
