import React from 'react';
import type { LineLocation } from '../Interfaces';
import {SelectionLine} from './SelectionLine';

interface SelectionLinesProps{
  activeLine:LineLocation;
  lines:LineLocation[];
}

export const SelectionLines = ({ activeLine, lines }:SelectionLinesProps) => (
		<>
			<SelectionLine
				from={activeLine.from}
				to={activeLine.to}
				isPlayer
			/>
			{lines.map((line, i) => (
        /* eslint-disable-next-line react/no-array-index-key */
				<SelectionLine key={i} from={line.from} to={line.to} isPlayer={false} />
			))}
		</>
	);
