/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from "react";
import type { CellLocation } from "../Interfaces";
import "../../../main.css";

interface CellProps {
  children: string;
  rowIndex: number;
  colIndex: number;
  hintCell: CellLocation;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onMouseDown: (e: any, row: number, col: number) => void;
  onMouseMove: (row: number, col: number) => void;
  onMouseUp: () => void;
}

export const Cell = ({
  children,
  hintCell,
  colIndex,
  rowIndex,
  onMouseDown,
  onMouseMove,
  onMouseUp,
}: CellProps) => {
  const getClass = () => {
    const classNameBasic = "porehu-cell noselect";

    if (hintCell?.row === rowIndex && hintCell.col === colIndex) {
      return `${classNameBasic} porehu-hint`;
    }

    return classNameBasic;
  };

  return (
    <td
      role="button"
      tabIndex={0}
      className={getClass()}
      onMouseDown={(e) => onMouseDown(e, rowIndex, colIndex)}
      onMouseMove={() => onMouseMove(rowIndex, colIndex)}
      onMouseUp={() => onMouseUp()}
    >
      {children}
    </td>
  );
};
