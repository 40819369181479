import React, { useEffect, useState } from "react";
import { GameType } from "./FlipperInterfaces";
import type { FlipperProps } from "./FlipperInterfaces";
import { Wordcard } from "./Utils/Wordcard";
import { UserControls } from "./Utils/UserControls";
import { compareAlphabetical } from "./Utils/compareAlphabetical";
import { shuffle } from "./Utils/shuffle";
import "../../main.css";

export interface CardData {
  id: number;
  language: string;
  text: string;
  inverse: string;
}

export const Flipper = ({
  autoSelect,
  data: transformedData,
  showMaori,
  showEnglish,
  lives,
}: FlipperProps) => {
  const [correct, setCorrect] = useState<number[]>([]);
  const [failedAttempts, setFailedAttempts] = useState<number>(0);
  const [sortedData, setSortedData] = useState<CardData[]>([]);
  const [resetId, setResetId] = useState(0);
  const [required, setRequired] = useState<number[]>([]);
  const [finished, setFinished] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [firstClicked, setFirstClicked] = useState<CardData>();
  const [gameType, setGameType] = useState<GameType>(GameType.ShowAll);
  const [idToSelect, setIdToSelect] = useState(0);
  const [cardClickCount, setCardClickCount] = useState(0);
  const [secondClick, setSecondClick] = useState<CardData>();

  const selectNext = () => {
    if (autoSelect) {
      const findLanguage = showMaori ? "english" : "maori";
      const findNext = sortedData.find(
        (val) =>
          val.language === findLanguage &&
          val.inverse.length > 0 &&
          !correct.includes(val.id)
      );
      const newIdToFind = findNext ? findNext.id : 0;
      setIdToSelect(newIdToFind);
    } else {
      setIdToSelect(0);
    }
  };

  const isFinished = () => {
    const stillNeeded = required.filter((val) => !correct.includes(val));
    if (stillNeeded.length > 0) {
      selectNext();
      setFinished(false);
    }

    if (stillNeeded.length === 0) {
      setFinished(true);
    }
    if (failedAttempts === lives) {
      setFinished(true);
    }
  };

  useEffect(() => {
    setRefresh(true);
  }, []);

  useEffect(() => {
    setCorrect([]);
    setFailedAttempts(0);
    const unique: number[] = [];
    transformedData.forEach((item) => {
      if (item.text.length > 0 && item.inverse.length > 0) {
        unique.push(item.id);
      }
    });
    unique.sort();
    setRequired(unique);
    setFinished(false);
    if (showMaori && !showEnglish) {
      setGameType(GameType.ShowAll);
    }
    if (!showMaori && showEnglish) {
      setGameType(GameType.ShowEnglish);
    }
    if (!showEnglish && showMaori) {
      setGameType(GameType.ShowMāori);
    }
    if (!showEnglish && !showMaori) {
      setGameType(GameType.ShowNone);
    }
  }, [transformedData]);

  useEffect(() => {
    const copy = transformedData.filter((item) => item.text.length > 0);
    copy.sort(compareAlphabetical);
    setSortedData(shuffle(copy));
  }, [transformedData]);

  const handleCardClick = (
    id: number,
    language: string,
    text: string,
    inverse: string
  ) => {
    if (firstClicked) {
      setSecondClick({ id, language, text, inverse });
    }
    const REFRESH_TIME = 1200;
    setCardClickCount(cardClickCount + 1);
    if (!firstClicked) {
      const cd: CardData = {
        id,
        language,
        text,
        inverse,
      };
      setFirstClicked(cd);

      return;
    }
    if (firstClicked?.text === text) {
      setFirstClicked(undefined);
    }
    if (id === idToSelect) {
      setIdToSelect(0);
    } else if (
      firstClicked?.inverse === text ||
      firstClicked.text === inverse
    ) {
      const viewCorrectTimeOut = setTimeout(() => {
        setCorrect(correct?.concat(firstClicked.id, id));
      }, REFRESH_TIME);
      setFirstClicked(undefined);

      return () => {
        clearTimeout(viewCorrectTimeOut);
      };
    } else {
      setFailedAttempts(failedAttempts + 1);

      const timeOut = setTimeout(() => {
        setResetId(id);
      }, REFRESH_TIME);

      return () => {
        clearTimeout(timeOut);
      };
    }
  };
  const resetFlipper = () => {
    setFinished(true);
    setFailedAttempts(0);
    setFirstClicked(undefined);
    setRefresh(true);
    setCorrect([]);
    setIdToSelect(0);
  };
  useEffect(() => {
    isFinished();
  }, [correct, required, failedAttempts]);

  return (
    <div className="flipper-base" data-testid="flipper-grid">
      {!finished && (
        <div className="flipper-card-container">
          {sortedData.map(({ text, id, language, inverse }) => (
            <Wordcard
              autoClickedId={idToSelect > 0 ? idToSelect : 0}
              id={id}
              inverse={inverse}
              correct={correct}
              language={language}
              gameType={gameType}
              key={id}
              onClick={handleCardClick}
              resetId={resetId}
              refresh={refresh}
              text={text}
            />
          ))}
        </div>
      )}
      <div className="flipper-card-container">
        <UserControls
          answerAttempt={secondClick?.text}
          answerValue={secondClick?.inverse}
          forceStartTimer={cardClickCount}
          inverse={firstClicked?.inverse}
          language={firstClicked?.language}
          text={firstClicked?.text}
          correct={correct}
          incorrect={failedAttempts}
          lives={lives}
          total={required.length / 2}
          reset={resetFlipper}
          endGame={finished}
        />
      </div>
    </div>
  );
};

// Test
Flipper.defaultProps = {
  autoSelect: true,
  showMāori: true,
  showEnglish: true,
  lives: 3,
};
