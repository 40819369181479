import React from "react";
import type { SelectedPoint } from "../Interfaces";
import "../../../main.css";

const getLineLength = (from: SelectedPoint, to: SelectedPoint) =>
  Math.sqrt((to.x - from.x) ** 2 + (to.y - from.y) ** 2);

const STAIGHTLINE = 180;
const VALID_AXES = 45;
const PADDING_LEFT = 3;
const PADDING_TOP = 14;

const getLineAngle = (from: SelectedPoint, to: SelectedPoint) =>
  (Math.atan2(to.y - from.y, to.x - from.x) * STAIGHTLINE) / Math.PI;

interface SectionLineProps {
  from: SelectedPoint | undefined;
  to: SelectedPoint | undefined;
  isPlayer: boolean;
}

export const SelectionLine = ({ from, to, isPlayer }: SectionLineProps) => {
  if (!from || !to) {
    return null;
  }

  const length = getLineLength(from, to);
  const angle = getLineAngle(from, to);

  let colorClass = "porehu-selected";
  if (isPlayer) {
    colorClass =
      angle % VALID_AXES !== 0
        ? "porehu-unavailable-path"
        : "porehu-available-path";
  }

  const style = {
    width: `${length}px`,
    left: `${from!.x + PADDING_LEFT}px`,
    top: `${from!.y - PADDING_TOP}px`,
    rotate: `rotate(${angle}deg)`,
    WebkitTransform: `rotate(${angle}deg)`,
  };

  return <div className={`porehu-line ${colorClass}`} style={style} />;
};
