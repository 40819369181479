import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Row } from './Row';
import { SelectionLines } from '../SelectionLines/SelectionLines';
import type { CellLocation, GridInterface, LineLocation, WordsInterface } from "../Interfaces";
import '../../../main.css';

const CELL_SIZE = 50;

const getCellCentre = (cell: CellLocation | null, offset) => {
  if (cell) {
    const { row, col } = cell;

    return {
      x: col * CELL_SIZE + CELL_SIZE / 2 + offset.left as number,
      y: row * CELL_SIZE + CELL_SIZE / 2 + offset.top as number,
    };
  }
};

interface GridProps {
  grid: GridInterface;
  words: WordsInterface[];
  setWords: (WordListInterface) => void;
  hintCell: CellLocation;
}
 
export const Grid = ({ grid, words, setWords, hintCell }: GridProps) => {
  const [mouseDown, setMouseDown] = useState(false);
  const [fromCell, setFromCell] = useState<CellLocation | null>(null);
  const [toCell, setToCell] = useState<CellLocation | null>(null);
  const [selectionLines, setSelectionLines] = useState<LineLocation[]>([]);
  const [gridOffset, setGridOffset] = useState<DOMRect>();
  const gridRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (gridRef.current) {
      const r = gridRef?.current?.getBoundingClientRect();
      setGridOffset(r);
    }

  }, []);
  useEffect(() => {
    setWords(words);
  }, []);

  useEffect(() => {
    const lines = words
      .filter((word) => word.isSelected)
      .map((word) => ({
        from: getCellCentre(word.fromCell, gridOffset),
        to: getCellCentre(word.toCell, gridOffset),
      }))

    setSelectionLines(lines);

  }, [words]);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const mouseDownHandler = (e:any, r:number, c:number) => {
    if (e.button === 2 || (e.nativeEvent && e.nativeEvent.which === 2)) {
      return;
    }
    const cell:CellLocation = { row: r, col: c }

    setMouseDown(true);
    setFromCell(cell);
    setToCell(cell);
  };

  const mouseMoveHandler = (r:number, c:number)  => {
    if (!mouseDown) {
      return;
    }

    const cell = { row: r, col: c }
    setToCell(cell);
  };

  const isHorizontal = (from: CellLocation, to: CellLocation) => from.row === to.row;
  const isVertical = (from: CellLocation, to: CellLocation) => from.col === to.col;
  const isDiagonal = (from: CellLocation, to: CellLocation) => Math.abs(from.row - to.row) === Math.abs(from.col - to.col)

  const getcell = (row: number, col: number) => {
    const cellNumber = (row * grid.width + col) * 2;

    return cellNumber;
  }

  const getWordSelected = () => {
    if (!fromCell || !toCell) return "";
    const gS = grid.gridIn2D;
    let s = "";
    if (isHorizontal(fromCell, toCell)) {
      if (fromCell.col < toCell.col) {
        for (let i = fromCell.col; i <= toCell.col; i += 1) {
          const cellNumber = getcell(fromCell.row, i,)
          s += gS.toString().charAt(cellNumber)
        }
      }
      else {
        for (let i = fromCell.col; i >= toCell.col; i -= 1) {
          const cellNumber = getcell(fromCell.row, i,)
          s += gS.toString().charAt(cellNumber)
        }
      }
    }
    else if (isVertical(fromCell, toCell)) {
      if (fromCell.row < toCell.row) {
        for (let i = fromCell.row; i <= toCell.row; i += 1) {
          const cellNumber = getcell(i, fromCell.col)
          s += gS.toString().charAt(cellNumber)
        }
      }
      else {
        for (let i = fromCell.row; i >= toCell.row; i -= 1) {
          const cellNumber = getcell(i, fromCell.col)
          s += gS.toString().charAt(cellNumber)
        }
      }
    }
    else if (isDiagonal(fromCell, toCell)) {
      const nextCol = fromCell.col < toCell.col ? 1 : -1;
      let colOffset = 0
      if (fromCell.row < toCell.row) {

        for (let i = fromCell.row; i <= toCell.row; i += 1) {
          const cellNumber = getcell(i, fromCell.col + colOffset)
          s += gS.toString().charAt(cellNumber)
          colOffset += nextCol
        }
      }
      else {
        for (let i = fromCell.row; i >= toCell.row; i -= 1) {
          const cellNumber = getcell(i, fromCell.col + colOffset)
          s += gS.toString().charAt(cellNumber)
          colOffset += nextCol
        }
      }
    }

    return s;
  }

  const mouseUpHandler = () => {
    if (!mouseDown) {
      return;
    }
    const selected = getWordSelected();
    const reversed = selected.split('').reverse().join('');
    const res = words.map((word) => {
      if (word.word === selected || word.word === reversed) {
        const newWord: WordsInterface = {
          isSelected: true,
          word: word.word,
          fromCell: fromCell!,
          toCell: toCell!
        }

        return newWord
      }

      return word;
    })

    setWords(res);
    setMouseDown(false);
    setFromCell(null);
    setToCell(null);

  };

  return (
    <div ref={gridRef} className='porehu-grid-wrapper porehu-prevent-select'>
      {gridOffset && (
        <SelectionLines
          lines={selectionLines}
          activeLine={{
            from: getCellCentre(fromCell, gridOffset),
            to: getCellCentre(toCell, gridOffset),
          }}
        />
      )}

      <table
        role="presentation"
        data-testid="searchgrid"
      >
        <tbody>
          {grid.gridIn2D.map((row, i) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <Row key={i} row={row} rowIndex={i} hintCell={hintCell} onMouseDown={mouseDownHandler}
              onMouseMove={mouseMoveHandler}
              onMouseUp={mouseUpHandler} />
          ))}
        </tbody>
      </table>

    </div>
  );
};
