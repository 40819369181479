import React, { useEffect, useState } from "react";
import type { TimerProps } from "../FlipperInterfaces";

export const Timer = ({ endGame, forceStartTimer, reset, win }: TimerProps) => {
  const [timerActive, setTimerActive] = useState(false);
  const [timeTotal, setTimeTotal] = useState(0);
  const TIMER_INTERVAL = 10;
  const MS_IN_MS = 10;
  const MS_IN_MIN = 60000;
  const SEC_IN_MIN = 60;
  const MIN_IN_HOUR = 60;
  const MS_IN_SECOND = 100;
  const MS_IN_SEC = 1000;
  const REMOVE_TRAILING_0 = -2;
  const calculateMinutes = Math.floor((timeTotal / MS_IN_MIN) % MIN_IN_HOUR);
  const calculateSeconds = Math.floor((timeTotal / MS_IN_SEC) % SEC_IN_MIN);
  const winStr = `Congratulations! You completed the game in
  ${
    calculateMinutes >= 1
      ? ` ${calculateMinutes} minutes and ${calculateSeconds} seconds.`
      : ` ${calculateSeconds} seconds`
  }`;
  const gameOverStr = `You ran out of lives.
    Game over!`;
  const startStopTimer = () => {
    setTimerActive(!timerActive);
  };

  useEffect(() => {
    if (!timerActive) {
      startStopTimer();
    }
    if (endGame && timerActive) {
      startStopTimer();
    }
  }, [forceStartTimer, endGame]);
  const resetTimer = () => {
    setTimeTotal(0);
    setTimerActive(false);
  };

  useEffect(() => {
    let interval;
    if (timerActive) {
      interval = setInterval(() => {
        setTimeTotal((time) => time + TIMER_INTERVAL);
      }, TIMER_INTERVAL);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timerActive]);

  return (
    <div className="timer-container">
      {endGame ? (
        <div className="timer-watch">{win ? winStr : gameOverStr}</div>
      ) : (
        <div className="timer-watch">
          {`0${Math.floor((timeTotal / MS_IN_MIN) % MIN_IN_HOUR)}`.slice(
            REMOVE_TRAILING_0
          )}
          :
          {`0${Math.floor((timeTotal / MS_IN_SEC) % SEC_IN_MIN)}`.slice(
            REMOVE_TRAILING_0
          )}
          .
          {`0${(timeTotal / MS_IN_MS) % MS_IN_SECOND}`.slice(REMOVE_TRAILING_0)}
        </div>
      )}
      <div>
        <button
          type="button"
          className="timer-button-play"
          onClick={startStopTimer}
        >
          {timerActive ? "Pause Game" : "Play Flipper"}
        </button>
        <button
          type="button"
          className="timer-button-reset"
          onClick={() => {
            reset();
            resetTimer();
          }}
        >
          Restart Flipper
        </button>
      </div>
    </div>
  );
};
