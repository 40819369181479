export interface CardData {
  id: number;
  language: string;
  text: string;
  inverse: string;
}
export interface WordcardProps {
  autoClickedId: number;
  correct: number[];
  id: number;
  inverse: string;
  language: string;
  gameType: GameType;
  onClick: (
    id: number,
    language: string,
    text: string,
    inverse: string
  ) => void;
  refresh: boolean;
  resetId: number;
  text: string;
}
export interface FlipperProps {
  autoSelect: boolean;
  data: CardData[];
  showMaori: boolean;
  showEnglish: boolean;
  lives: number;
}

export interface TimerProps {
  endGame: boolean;
  forceStartTimer: number;
  reset: () => void;
  win: boolean;
}
export interface UserControlProps {
  answerAttempt: string | undefined;
  answerValue: string | undefined;
  correct: number[];
  forceStartTimer: number;
  endGame: boolean;
  language: string  | undefined;
  lives: number;
  incorrect: number;
  inverse: string | undefined;
  reset: () => void;
  text: string | undefined;
  total: number;
}

export interface ScoreProps {
  gameEnd: boolean;
  incorrect: number;
  lives: number;
  score: number;
  total: number;
}

export enum GameType {
  ShowMāori,
  ShowEnglish,
  ShowAll,
  ShowNone,
}
