/* eslint-disable react/jsx-props-no-spreading */
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { GameType } from "../FlipperInterfaces";
import type { WordcardProps } from "../FlipperInterfaces";
import "../../../main.css";

export const Wordcard = ({
  autoClickedId,
  correct,
  id,
  inverse,
  language,
  gameType,
  onClick,
  refresh,
  resetId,
  text,
}: WordcardProps) => {
  const [selected, setSelected] = useState(false);
  const [visible, setVisible] = useState(false);
  const [faceUp, faceDown] = useState(false);
  const flipCard = {
    rotateY: 360,
    transition: { duration: 0.75 },
  };
  const flipBack = {
    rotateY: 360,
    transition: { duration: 0.65 },
  };
  const imageSrc =
    language === "maori"
      ? `https://assets.tereomaori.nz/images/taotrLogo.png`
      : `https://assets.tereomaori.nz/images/taotrLogoBW.png`;

  useEffect(() => {
    if (refresh) {
      setSelected(false);
    }
  }, [refresh]);
  useEffect(() => {
    if (resetId === id) {
      setVisible(false);
      setSelected(false);
    }
  }, [resetId]);
  const classNameGenerator = (...classes: string[]) => classes.join(" ");

  const handleClick = (languageStr: string) => {
    setSelected(true);
    onClick(id, languageStr, text, inverse);
    setVisible(!visible);
  };

  useEffect(() => {
    if (autoClickedId === id) {
      handleClick(language);
    }
  }, [autoClickedId]);

  useEffect(() => {
    if (gameType === GameType.ShowMāori && language === "maori") {
      setVisible(true);
    }
    if (gameType === GameType.ShowEnglish && language === "english") {
      setVisible(true);
    }
    if (gameType === GameType.ShowAll) {
      setVisible(true);
    }
    if (gameType === GameType.ShowNone && autoClickedId === 0) {
      setVisible(visible);
    }
    if (visible) {
      faceDown(!faceUp);
    }
  }, [visible]);

  return (
    <div
      className="flipper-wordcard-container"
      data-testid="flipper-card-container"
      id={`card"${id.toString()}`}
    >
      <motion.div
        initial="initial"
        animate={!visible && selected && faceUp ? flipCard : flipBack}
        onClick={() => {
          handleClick(language);
        }}
      >
        {visible ? (
          <button
            type="button"
            data-testid={`visible-card-${id.toString()}`}
            className={classNameGenerator(
              selected ? "flipper-wordcard-selected" : "flipper-wordcard",
              correct.includes(id)
                ? "flipper-wordcard-matched"
                : "flipper-wordcard",
              autoClickedId === id
                ? "flipper-wordcard-selected"
                : "flipper-wordcard"
            )}
          >
            <div className="flipper-wordcard-text">{text}</div>
          </button>
        ) : (
          <button
            className={classNameGenerator(
              "flipper-wordcard",
              correct.includes(id)
                ? "flipper-wordcard-matched"
                : "flipper-wordcard"
            )}
            type="button"
            data-testid={`flipped-card-${id.toString()}`}
          >
            <img
              className="flipper-card-image"
              src={imageSrc}
              alt="flip card"
            />
          </button>
        )}
      </motion.div>
    </div>
  );
};
