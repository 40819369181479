import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination, Virtual } from "swiper";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  faPause,
  faPlay,
  faExpandAlt,
  faCompressAlt,
} from "@fortawesome/free-solid-svg-icons";
import "swiper/swiper-bundle.min.css";
import "../../main.css";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tipuSet } from "./Utils/FetchTipu";

SwiperCore.use([Autoplay, Navigation, Pagination, Virtual]);

export const TipuSwipe = () => {
  const handle = useFullScreenHandle();

  const [imageArr, setImages] = useState<string[]>([]);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();

  useEffect(() => {
    const runAsync = async () => {
      const oneImage = await tipuSet(true);

      setImages(oneImage);

      const allImages = await tipuSet(false);
      setImages(allImages);
    };

    runAsync();
  }, []);

  const slides = imageArr.map((_el) => `${_el}`);
  const play: () => void = () => swiperInstance?.autoplay.start() as unknown;
  const pause: () => void = () => swiperInstance?.autoplay.stop() as unknown;
  const [isPlaying, setIsPlaying] = useState(true);
  const playEl = faPlay as IconProp;
  const pauseEl = faPause as IconProp;
  const [isFull, setIsFull] = useState(true);
  const fullScreenEnter = faExpandAlt as IconProp;
  const fullScreenExit = faCompressAlt as IconProp;

  return (
    <Container bsPrefix="tipuTips">
      <FullScreen handle={handle} className="tipuTips">
        <Container className=".swiper-container">
          {slides.length > 0 ? (
            <>
              <Swiper
                simulateTouch
                preventClicks={false}
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{ delay: 5000 }}
                navigation
                init={false}
                pagination={{ type: "progressbar" }}
                onSwiper={setSwiperInstance}
              >
                {slides.map((slideContent, index) => (
                  <SwiperSlide key={slideContent} virtualIndex={index}>
                    <img src={slideContent} alt={slideContent} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Row className="row-menu-controls">
                <Col className="tipuTips-col">
                  <Button
                    className="tipuTips-btn btn-primary"
                    data-testid="play-pause-button"
                    onClick={() => {
                      if (isPlaying) {
                        pause();
                      } else {
                        play();
                      }
                      setIsPlaying(!isPlaying);
                    }}
                  >
                    {isPlaying ? (
                      <FontAwesomeIcon icon={pauseEl} />
                    ) : (
                      <FontAwesomeIcon icon={playEl} />
                    )}
                  </Button>
                  <Button
                    className="tipu-btn btn-primary"
                    data-testid="fullscreen-view"
                    onClick={() => {
                      if (isFull) {
                        handle.enter();
                      } else {
                        handle.exit();
                      }
                      setIsFull(!isFull);
                    }}
                  >
                    {isFull ? (
                      <FontAwesomeIcon icon={fullScreenEnter} />
                    ) : (
                      <FontAwesomeIcon icon={fullScreenExit} />
                    )}
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Container bsPrefix="tipuTips-spinner">
              <Spinner
                style={{
                  height: "3rem",
                  width: "3rem",
                  display: "flex",
                  alignItems: "center",
                }}
                animation="grow"
                size="sm"
                role="status"
                variant="info"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Container>
          )}
        </Container>
      </FullScreen>
    </Container>
  );
};
