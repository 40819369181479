import React, { useEffect, useState } from "react";
import { Rules } from "./Rules";
import { ToggleButton } from "../../WhakaMāori/Utils/Lesson/Toggle";
import type { UserControlProps } from "../FlipperInterfaces";
import { Score } from "./Score";
import { Timer } from "./Timer";

export const UserControls = ({
  answerAttempt,
  answerValue,
  correct,
  endGame,
  forceStartTimer,
  language,
  lives,
  incorrect,
  inverse,
  reset,
  text,
  total,
}: UserControlProps) => {
  const defaultTheme = "#2E727B";
  const [toggleOpen, setIsToggleOpen] = useState(true);
  const [correctCount, setCorrectCount] = useState(0);
  const [match, setMatch] = useState(true);
  const incorrectStr = `You were looking for the translation of ${text} which means
  ${inverse}, but you chose ${answerAttempt} which means ${answerValue}`;
  const correctStr = `You chose ${answerAttempt} which means ${answerValue}, good work!`;

  useEffect(() => {
    if (answerAttempt !== text) {
      setMatch(false);
    } else setMatch(true);
  }, [match, answerAttempt]);
  useEffect(() => {
    if (correct.length > 0) {
      setCorrectCount(correctCount + 1);
    } else if (correct.length === 0) {
      setCorrectCount(0);
    }
  }, [correct]);

  const toggle = () => {
    setIsToggleOpen(!toggleOpen);
  };

  return (
    <div className="flipper-controls-container">
      <Score
        gameEnd={endGame}
        incorrect={incorrect}
        lives={lives}
        score={correctCount}
        total={total}
      />
      <Timer
        endGame={endGame}
        forceStartTimer={forceStartTimer}
        reset={reset}
        win={incorrect < lives}
      />
      <Rules />
      <div className="flipper-card-container-hints">
        <div className="timer-watch">Show Hints</div>
        <ToggleButton
          toggleWidget={toggle}
          moduleState={toggleOpen}
          themeColour={defaultTheme}
        />
        <div style={{}}>
          {toggleOpen && text && !answerAttempt && (
            <div>
              You selected {text} the {language} word for {inverse}
            </div>
          )}
          {toggleOpen && answerAttempt && answerAttempt.length !== 0 && (
            <div>{match ? correctStr : incorrectStr}</div>
          )}
        </div>
      </div>
    </div>
  );
};
